<template lang="">
  <modal-padrao
    ref="modal-alterar-senha"
    :max-width="500"
    :titulo="$t('modulos.usuarios.botoes.alterar_senha')"
    :subtitulo="usuario?.nome"
    :titulo-ok="$t('geral.botoes.salvar')"
    :titulo-cancelar="$t('geral.botoes.fechar')"
    @ok="salvar"
  >
    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-model="form.senha"
        class="col-12 col-md-12"
        :label="$t('modulos.usuarios.formulario.senha')"
        obrigatorio
        type="password"
      />
      <input-text
        v-model="form.senhaConfirmacao"
        class="col-12 col-md-12"
        :label="$t('modulos.usuarios.formulario.confirmar_senha')"
        obrigatorio
        type="password"
        :regras-personalizadas="[validacaoConfirmacaoSenha]"
      />
    </v-form>
  </modal-padrao>
</template>
<script>
  import UsuarioService from '@common/services/cadastros/UsuarioService';
  export default {
    data() {
      return {
        form: {},
        usuario: {}
      };
    },
    methods: {
    validacaoConfirmacaoSenha: function (v) {
      if (!v) return true;
      if (this.form.senha != this.form.senhaConfirmacao)
        return this.$t('modulos.usuarios.validacoes.senha');
      return true;
    },
      abrirModal: function (usuario) {
        this.usuario = usuario;
        this.$refs["modal-alterar-senha"].abrirModal();
        this.form = {};
        this.$refs.form?.resetValidation();
      },
      salvar() {
        if(!this.$refs['form'].validate()) return;
        UsuarioService.alterarSenha(this.form, this.usuario?.id)
            .then(() => {
                this.$refs['modal-alterar-senha'].fecharModal();
                this.toastSucesso(this.$t('modulos.usuarios.senha_alterada_sucesso'));
            })
      },
    },
  };
</script>
  