<template>
  <div class="row">
    <input-text
      v-model="filtro.nome"
      class="col-12 col-md-6"
      :label="$t('modulos.usuarios.filtro.nome')"
      em-filtro
    />
    <input-text
      v-model="filtro.login"
      class="col-12 col-md-6"
      :label="$t('modulos.usuarios.filtro.login')"
      :mascara="false"
      em-filtro
    />
    <input-text
      v-model="filtro.email"
      class="col-12 col-md-6"
      :label="$t('modulos.usuarios.filtro.email')"
      :mascara="false"
      em-filtro
    />
    <input-text
      v-model="filtro.nomePapel"
      class="col-12 col-md-6"
      :label="$t('modulos.usuarios.filtro.papel')"
      :mascara="false"
      em-filtro
    />
    <input-select
      v-model="filtro.flagParticipanteConta"
      class="col-12 col-md-12"
      :label="$t('modulos.usuarios.filtro.participante_conta')"
      :options="opcoes.boleano"
      em-filtro
      retornar-objeto
    />
  </div>
</template>
<script>
import helpers from '@common/utils/helpers'
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes:{
        boleano: helpers.BoleanoEnum,
      },
    };
  },
};
</script>
