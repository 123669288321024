<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.usuarios.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.usuarios.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('Usuarios', 'Inserir')"
      :sem-filtro="!buscarPermissao('Usuarios', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="true"
      filtros-com-colunas
      :mostrar-seletor="false"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
      @filtrarGlobal="listarRegistros"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao text color="secondary">
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item
            v-if="buscarPermissao('Usuarios', 'Editar')"
            @click="abrirEditar(slotProps.data)"
          >
            {{ $t('geral.botoes.editar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('Usuarios', 'AlterarSenha')"
            @click="alterarSenha(slotProps.data)"
          >
            {{ $t('modulos.usuarios.botoes.alterar_senha'), }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="
              buscarPermissao('Usuarios', 'AtivarUsuario') ||
              buscarPermissao('Usuarios', 'InativarUsuario')
            "
            @click="ativarInativar(slotProps.data)"
          >
            {{
              $t(
                `geral.botoes.${
                  !slotProps.data.flagAtivo
                    ? 'ativar_usuario'
                    : 'inativar_usuario'
                }`
              )
            }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
      <template v-slot:flagAtivo="{ slotProps }">
        <div
          :class="`${
            DeixarComoAtivoOuInativo(slotProps.data.flagAtivo).cor
          }--text`"
        >
          {{ DeixarComoAtivoOuInativo(slotProps.data.flagAtivo).text }}
        </div>
      </template>
      <template v-slot:participantes="{ slotProps }">
        <coluna-multiplos-itens
          v-if="slotProps.data?.participantes?.length"
          custom-style="max-width: 1000px;"
          :item="MontarViewParticipantes(slotProps.data?.participantes)"
        />
      </template>
    </tabela-padrao-prime-vue>
    <modal-alterar-senha ref="modal-alterar-senha" />
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import UsuarioService from '@common/services/cadastros/UsuarioService';
import Filtro from './filtro.vue';
import helpers from '@common/utils/helpers';
import ColunaMultiplosItens from '@/components/tabelas/ColunaMultiplosItens.vue';
import ModalAlterarSenha from './modais/ModalAlterarSenha.vue';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
    ColunaMultiplosItens,
    ModalAlterarSenha,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'login',
            text: this.$t('modulos.usuarios.tabela.login'),
            sortable: true,
          },
          {
            value: 'nome',
            text: this.$t('modulos.usuarios.tabela.nome'),
            sortable: true,
            width: 250,
          },
          {
            value: 'participantes',
            text: this.$t('modulos.usuarios.tabela.participantes'),
            sortable: false,
          },
          {
            value: 'email',
            text: this.$t('modulos.usuarios.tabela.email'),
            sortable: true,
          },
          {
            value: 'papel.nome',
            text: this.$t('modulos.usuarios.tabela.papel'),
            sortable: true,
            formatter: (v) => {
              return v;
            },
          },
          {
            value: 'flagAtivo',
            text: this.$t('modulos.usuarios.tabela.situacao'),
            sortable: true,
            formatter: (v) => {
              return this.$t(`geral.titulos.${v ? 'ativo' : 'inativo'}`);
            },
          },
          {
            value: 'colaborador.nome',
            text: this.$t('modulos.usuarios.tabela.colaborador'),
            sortable: true,
          },
          {
            value: 'participanteContato.nome',
            text: this.$t('modulos.usuarios.tabela.contato'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      colunaOrdenada: '',
    };
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Usuarios', 'Listar');
    const filtros = helpers.buscarStateFiltros(this);
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.usuarios.titulos.listagem')
    );
    this.listarRegistros();
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    alterarSenha(usuario) {
      this.$refs['modal-alterar-senha'].abrirModal(usuario);
    },
    MontarViewParticipantes(participantes) {
      return {
        value: participantes?.map((v) => {
          return { nome: v?.apelido };
        }),
      };
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros(
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      helpers.salvarStateFiltros(this, {
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        sort: this.colunaOrdenada,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.colunas = [];
      UsuarioService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    DeixarComoAtivoOuInativo: function (v) {
      return helpers.AtivoInativoEnum.find((el) => el.value == v);
    },
    abrirNovo: function () {
      this.$router.push({ name: 'usuario-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'usuario-editar',
        params: { id: item.id },
      });
    },
    ativarInativar: function (item) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      UsuarioService.ativarInativar(item.id, item.flagAtivo)
        .then(() => {
          if (item.flagAtivo) {
            this.toastSucesso(this.$t(`modulos.usuarios.inativado_sucesso`));
          } else {
            this.toastSucesso(this.$t(`modulos.usuarios.ativado_sucesso`));
          }
          this.listarRegistros(this.tabela.paginaAtual, this.tabela.porPagina);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
